<template>
  <v-row align="center" justify="center">
    <v-col cols="12" xl="6" lg="9">
      <v-alert v-for="(alert, i) in activeAlerts" :key="i" :type="alert.type">
        {{ alert.text }}
      </v-alert>
      <v-card>
        <v-img
          class="white--text align-end"
          height="250px"
          src="@/assets/header.jpg"
          gradient="to top, rgba(0, 0, 0, 0.7), transparent 35%"
        >
          <v-card-title>
            Financial Services Application Portal
          </v-card-title>
        </v-img>

        <v-list>
          <v-list-item dense>
            <v-text-field
              v-model="search"
              solo
              hide-details
              clearable
              placeholder="Search by name or description."
              prepend-inner-icon="mdi-magnify"
              class="my-2"
            ></v-text-field>
          </v-list-item>
          <v-list-item v-for="app in apps" :key="app.name" :href="app.url">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon :color="app.status">mdi-circle-medium</v-icon>
                <span class="font-weight-medium">{{ app.name }}</span>
                <span class="ml-2 body-2 font-weight-light">{{
                  app.description
                }}</span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-if="app.requiresVpn" size="14" v-on="on">
                    mdi-lock
                  </v-icon>
                </template>
                <span>Requires VPN</span>
              </v-tooltip>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import apps from '@/data/apps.json'
import alerts from '@/data/alerts.json'

export default {
  data() {
    return {
      alerts,
      search: '',
    }
  },
  computed: {
    activeAlerts() {
      return this.alerts.filter(({ active }) => active)
    },
    apps() {
      if (!this.search) return apps
      return apps.filter(app => {
        return (
          app.name.includes(this.search) ||
          app.description.includes(this.search)
        )
      })
    },
  },
}
</script>
