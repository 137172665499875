import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary_light: '#378dbd', // Light Blue
        primary: '#1e5288', // Medium Blue
        primary_dark: '#0c234b', // Navy Blue
        accent: '#ab0520', // UA Red
        success: '#70b865', // Light Green
        warning: '#f19e1f', // Yellow
        danger: '#ef4056', // Light Red
      },
    },
  },
})
