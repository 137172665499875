<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
      <v-list>
        <v-subheader>Quick Links</v-subheader>
        <v-list-item
          v-for="link in links"
          :key="link.name"
          :href="link.url"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>{{ 'mdi-' + link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ link.name }}
              </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="clipped" fixed app color="#AB0520" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="pt-1">
        <a href="https://www.arizona.edu/"
          ><img
            src="@/assets/ua_brand.svg"
            alt="The University of Arizona"
            width="250"
        /></a>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container>
        <Index />
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from '@/components/Footer'
import Index from '@/components/Index'
import links from '@/data/links.json'
export default {
  components: {
    Index,
    Footer,
  },
  data() {
    return {
      links,
      clipped: false,
      drawer: false,
      footer_links: [
        {
          text: 'University Privacy Statement',
          href: 'https://privacy.arizona.edu/privacy-statement',
        },
        {
          text: 'Security & Privacy',
          href: 'https://www.arizona.edu/information-security-privacy',
        },
        {
          text: 'Copyright',
          href: 'https://www.arizona.edu/copyright',
        },
      ],
    }
  },
  computed: {
    year() {
      const date = new Date()
      return date.getFullYear()
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-links a {
  color: #333;
  font-size: 0.9rem;
  margin: 0 0.5rem;
}
</style>
